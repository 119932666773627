<template>
  <div class="input-box">
    <input
      :id="id"
      :type="type"
      :placeholder="placeholder"
      :name="name"
      :required="required"
      :value="value"
      class="input"
      :class="{
        error: error && errorBorderVisible,
        password: isExistPasswordSwitcher,
      }"
      @focus="$emit('isFocus', name)"
      @blur="$emit('blur')"
      @input="$emit('input', $event.target.value)"
      @keyup.enter="$event.target.blur()"
    />
    <button
      v-if="isExistPasswordSwitcher"
      type="button"
      class="password-switcher"
      @click="$emit('toggle-password')"
    >
      <svg-icon :name="passwordSwitcherIcon"></svg-icon>
    </button>
    <InputError v-if="error" :error-txt="errorTxt" :is-show-icon="isShowIcon" />
    <div v-if="isShowInfoMessage && !error" class="input-box__info">
      <svg-icon name="hint" class="input-box__hint"></svg-icon>
      {{ title }}
    </div>
  </div>
</template>

<script>
import InputError from '@/elements/Errors/InputError.vue';

export default {
  components: {
    InputError,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorBorderVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    errorTxt: {
      type: String,
      default: '',
    },
    isShowIcon: {
      type: Boolean,
      default: true,
    },
    isShowInfoMessage: {
      type: Boolean,
      default: false,
    },
    isExistPasswordSwitcher: {
      type: Boolean,
      default: false,
    },
    isShowPassword: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    passwordSwitcherIcon() {
      return this.isShowPassword ? 'visible-password' : 'hidden-password';
    },
  },
};
</script>

<style lang="sass" scoped>
.input-box
  display: flex
  flex-direction: column
  width: 100%
  align-items: flex-start
  position: relative

.input-box__info
  display: flex
  align-items: center
  font-size: 14px
  position: absolute
  bottom: -20px
  left: 0

.input-box__hint
  width: 14px
  height: 14px
  margin-right: 4px

.input
  background-color: var(--primary-color-white)
  border-radius: var(--primary-border-radius)
  border: 1px solid var(--primary-color-dark-grey-1)
  font-weight: 600
  color: var(--primary-color-dark-blue)
  width: inherit
  height: 64px
  padding: 20px
  font-size: 18px

.input:hover
  background-color: rgba(40, 59, 89, 0.02)
  border: 1px solid var(--primary-color-dark-blue)

.input:focus
  background-color: transparent
  border: 2px solid var(--primary-color-dark-blue)

.input::-webkit-input-placeholder
  color: var( --primary-color-dark-grey-1)
  font-weight: normal

.input:-moz-placeholder
  color: var( --primary-color-dark-grey-1)
  font-weight: normal

.input::-moz-placeholder
  color: var( --primary-color-dark-grey-1)
  font-weight: normal

.input:-ms-input-placeholder
  color: var( --primary-color-dark-grey-1)
  font-weight: normal

.input::-ms-input-placeholder
  color: var( --primary-color-dark-grey-1)
  font-weight: normal

.input::placeholder
  color: var( --primary-color-dark-grey-1)
  font-weight: normal

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active
  transition: background-color 5000s ease-in-out 0s
  -webkit-box-shadow: 0 0 0px 1000px var(--primary-color-white) inset

.input.error
  border: 1px solid var(--primary-color-error)

.input.password
  padding-right: 70px

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

.input[type='number']
  -moz-appearance: textfield

.password-switcher
  position: absolute
  top: 50%
  right: 22px
  transform: translateY(-50%)
  display: flex

.password-switcher:after
  position: absolute
  content: ''
  width: 32px
  height: 32px
  top: -4px
  left: -4px
  background-color: var(--primary-color-dark-grey-1)
  border-radius: 50%
  opacity: 0
  transition: var(--transition-speed)


.password-switcher:hover:after
  opacity: 0.15
</style>
